import { CmpApi } from "@iabtechlabtcf/cmpapi";
import { tcfCmpApiDispatcher } from "@devowl-wp/cookie-consent-web-client";
import { yieldMainThread } from "@devowl-wp/react-utils";
import { getCookieConsentManager } from "../others/getCookieConsentManager.js";
import { getCmpVersion } from "../utils/getCmpVersion.js";
import { getOtherOptionsFromWindow } from "../utils/getOtherOptionsFromWindow.js";
let cmpApiInstance;
/**
 * Initialize a CMP API once for this page request. If TCF is not available, it will
 * return `undefined`.
 *
 * @see https://www.npmjs.com/package/@iabtechlabtcf/cmpapi
 */ function tcfCmpApi() {
    const other = getOtherOptionsFromWindow();
    const { frontend: { isTcf, tcfMetadata } } = other;
    if (process.env.IS_TCF === "1" && isTcf) {
        const { scope } = tcfMetadata;
        // Create instance only once
        if (!cmpApiInstance) {
            cmpApiInstance = new CmpApi(+process.env.TCF_CMP_ID, // Currently, only two bits (https://git.io/JmuD0) can be saved to the cmpVersion, so we can only save the major version
            getCmpVersion("major"), scope === "service-specific");
            // Dispatch events to vendors can listen to it
            yieldMainThread().then(()=>tcfCmpApiDispatcher(// Access lazily through Proxy.get
                other.frontend.tcf, tcfMetadata, cmpApiInstance, getCookieConsentManager().getOption("tcfCookieName")));
        }
        return cmpApiInstance;
    } else {
        return undefined;
    }
}
export { tcfCmpApi };
