import { consent } from "./consent.js";
import { consentAll } from "./consentAll.js";
import { consentSync } from "./consentSync.js";
import { unblock } from "./unblock.js";
import { unblockSync } from "./unblockSync.js";
import { wrapFn } from "./wrapFn.js";
/**
 * Create the API functions and make them available through `window.consentApi`.
 *
 * There is also a [stub](https://shorturl.at/bcgyz) implementation:
 *
 * ```js
 * ((a,b)=>{a[b]||(a[b]={unblockSync:()=>undefined},["consentSync"].forEach(c=>a[b][c]=()=>({cookie:null,consentGiven:!1,cookieOptIn:!0})),["consent","consentAll","unblock"].forEach(c=>a[b][c]=(...d)=>new Promise(e=>a.addEventListener(b,()=>{a[b][c](...d).then(e)},{once:!0}))))})(window,"consentApi");
 * ```
 */ function createApi(manager, blocker) {
    const apiConsent = function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        return consent(manager, ...args);
    };
    const apiConsentAll = function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        return consentAll(manager, ...args);
    };
    const apiConsentSync = function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        return consentSync(manager, ...args);
    };
    const apiConsentUnblockSync = function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        return unblockSync(blocker, ...args);
    };
    const apiConsentUnblock = function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        return unblock(blocker, ...args);
    };
    const wrapApis = {
        consent: apiConsent,
        consentAll: apiConsentAll,
        consentSync: apiConsentSync,
        unblock: apiConsentUnblock,
        unblockSync: apiConsentUnblockSync
    };
    const apiWrapFn = function() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        return wrapFn(wrapApis, blocker, manager, ...args);
    };
    const api = {
        ...wrapApis,
        wrapFn: apiWrapFn
    };
    window.consentApi = api;
    // Dispatch event so e.g. stubs can react to it and forward functions
    window.dispatchEvent(new CustomEvent("consentApi"));
    return api;
}
export { createApi };
