import Cookie from "js-cookie";
import { EServiceTemplateGoogleConsentModeTypes } from "@devowl-wp/api-real-cookie-banner";
import { getUserGcmDecision } from "./getUserDecision.js";
import { PRESET_ID_TAG_MANAGERS } from "../apply/getManagerDependingVariables.js";
import { APPLY_INTERACTIVE_EVENT } from "../events/applyInteractive.js";
/**
 * Dispatch events to the `gtag` so the Google Consent Mode can work with the consent status.
 */ function gcmDispatcher(param) {
    let { gcmCookieName, groups, setCookiesViaManager } = param;
    document.addEventListener(APPLY_INTERACTIVE_EVENT, (param)=>{
        let { detail: { services: acceptedServices } } = param;
        const { gtag } = window;
        const cookieExists = !!Cookie.get(gcmCookieName);
        if (gtag && cookieExists) {
            const consent = getUserGcmDecision(gcmCookieName);
            gtag("consent", "update", {
                // Custom consent types representing our unique name of each service
                ...setCookiesViaManager === "googleTagManagerWithGcm" ? groups.map((param)=>{
                    let { items } = param;
                    return items;
                }).flat().reduce((p, param)=>{
                    let { id, uniqueName } = param;
                    // @ts-expect-error Unique name can be every preset ID
                    if (uniqueName && PRESET_ID_TAG_MANAGERS.indexOf(uniqueName) === -1) {
                        p[uniqueName] = acceptedServices.some((param)=>{
                            let { service: { id: acceptedId } } = param;
                            return acceptedId === id;
                        }) ? "granted" : "denied";
                    }
                    return p;
                }, {}) : [],
                // Predefined consent types
                ...Object.values(EServiceTemplateGoogleConsentModeTypes).reduce((p, c)=>{
                    p[c] = consent.indexOf(c) > -1 ? "granted" : "denied";
                    return p;
                }, {})
            });
        }
    });
}
export { gcmDispatcher };
