const TRANSLATABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST = [
    "name",
    "headline",
    "subHeadline",
    "providerNotice",
    "groupNotice",
    "legalBasisNotice",
    "technicalHandlingNotice",
    "createContentBlockerNotice",
    "sccConclusionInstructionsNotice"
];
Object.freeze(TRANSLATABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST);
const SERVICE_CDN_HIDDEN_CLIENT_PROPS = [
    "codeOnPageLoad",
    "codeOptIn",
    "codeOptOut",
    "createContentBlockerNotice",
    "deleteTechnicalDefinitionsAfterOptOut",
    "dynamicFields",
    "executeCodeOptInWhenNoTagManagerConsentIsGiven",
    "executeCodeOptOutWhenNoTagManagerConsentIsGiven",
    "googleConsentModeConsentTypes",
    "groupNotice",
    "isProviderCurrentWebsite",
    "legalBasis",
    "legalBasisNotice",
    "provider",
    "providerContact",
    "providerLegalNoticeUrl",
    "providerNotice",
    "providerPrivacyPolicyUrl",
    "providerText",
    "purposes",
    "purposeText",
    "shouldUncheckContentBlockerCheckbox",
    "shouldUncheckContentBlockerCheckboxWhenOneOf",
    "tagManagerOptInEventName",
    "tagManagerOptOutEventName",
    "technicalHandlingNotice"
];
Object.freeze(SERVICE_CDN_HIDDEN_CLIENT_PROPS);
const EXTENDABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST = [
    "name",
    "codeOnPageLoad",
    "googleConsentModeConsentTypes",
    "codeOptIn",
    "codeOptOut",
    "createContentBlockerNotice",
    "dataProcessingInCountries",
    "dataProcessingInCountriesSpecialTreatments",
    "deleteTechnicalDefinitionsAfterOptOut",
    "dynamicFields",
    "executeCodeOptInWhenNoTagManagerConsentIsGiven",
    "executeCodeOptOutWhenNoTagManagerConsentIsGiven",
    "executePriority",
    "group",
    "groupNotice",
    "isCdn",
    "isEmbeddingOnlyExternalResources",
    "isProviderCurrentWebsite",
    "legalBasis",
    "legalBasisNotice",
    "provider",
    "providerNotice",
    "providerPrivacyPolicyUrl",
    "providerLegalNoticeUrl",
    "purposes",
    "sccConclusionInstructionsNotice",
    "shouldUncheckContentBlockerCheckbox",
    "shouldUncheckContentBlockerCheckboxWhenOneOf",
    "tagManagerOptInEventName",
    "tagManagerOptOutEventName",
    "technicalDefinitions",
    "technicalHandlingNotice"
];
Object.freeze(EXTENDABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST);
var EServiceTemplateGroup;
(function(EServiceTemplateGroup) {
    EServiceTemplateGroup["Essential"] = "essential";
    EServiceTemplateGroup["Functional"] = "functional";
    EServiceTemplateGroup["Statistics"] = "statistics";
    EServiceTemplateGroup["Marketing"] = "marketing";
})(EServiceTemplateGroup || (EServiceTemplateGroup = {}));
var EServiceTemplateLegalBasis;
(function(EServiceTemplateLegalBasis) {
    EServiceTemplateLegalBasis["Consent"] = "consent";
    EServiceTemplateLegalBasis["LegitimateInterest"] = "legitimate-interest";
    EServiceTemplateLegalBasis["LegalRequirement"] = "legal-requirement";
})(EServiceTemplateLegalBasis || (EServiceTemplateLegalBasis = {}));
var EServiceTemplateDataProcessingInCountriesSpecialTreatment;
(function(EServiceTemplateDataProcessingInCountriesSpecialTreatment) {
    EServiceTemplateDataProcessingInCountriesSpecialTreatment["ContractualAssurancesWithSubProcessors"] = "contractual-assurances-with-sub-processors";
    EServiceTemplateDataProcessingInCountriesSpecialTreatment["ProviderIsSelfCertifiedTransAtlanticDataPrivacyFramework"] = "provider-is-self-certified-trans-atlantic-data-privacy-framework";
    EServiceTemplateDataProcessingInCountriesSpecialTreatment["StandardContractualClauses"] = "standard-contractual-clauses";
    // Currently only used for TCF standard, cannot be customized in Real Cookie Banner
    EServiceTemplateDataProcessingInCountriesSpecialTreatment["BindingCorporateRules"] = "binding-corporate-rules";
})(EServiceTemplateDataProcessingInCountriesSpecialTreatment || (EServiceTemplateDataProcessingInCountriesSpecialTreatment = {}));
var EServiceTemplateGoogleConsentModeTypes;
(function(EServiceTemplateGoogleConsentModeTypes) {
    EServiceTemplateGoogleConsentModeTypes["AdStorage"] = "ad_storage";
    EServiceTemplateGoogleConsentModeTypes["AdUserData"] = "ad_user_data";
    EServiceTemplateGoogleConsentModeTypes["AdPersonalization"] = "ad_personalization";
    EServiceTemplateGoogleConsentModeTypes["AnalyticsStorage"] = "analytics_storage";
    EServiceTemplateGoogleConsentModeTypes["FunctionalityStorage"] = "functionality_storage";
    EServiceTemplateGoogleConsentModeTypes["PersonalizationStorage"] = "personalization_storage";
    EServiceTemplateGoogleConsentModeTypes["SecurityStorage"] = "security_storage";
})(EServiceTemplateGoogleConsentModeTypes || (EServiceTemplateGoogleConsentModeTypes = {}));
var ServiceTemplateField;
/**
 * Represents the fields of a service template.
 * Please also have a look at TemplateMetaDataField for base fields.
 */ (function(ServiceTemplateField) {
    ServiceTemplateField["CodeOnPageLoad"] = "codeOnPageLoad";
    ServiceTemplateField["CodeOptIn"] = "codeOptIn";
    ServiceTemplateField["CodeOptOut"] = "codeOptOut";
    ServiceTemplateField["CreateContentBlockerNotice"] = "createContentBlockerNotice";
    ServiceTemplateField["CreateContentBlockerNoticeTranslationFlags"] = "createContentBlockerNoticeTranslationFlags";
    ServiceTemplateField["DataProcessingInCountries"] = "dataProcessingInCountries";
    ServiceTemplateField["DataProcessingInCountriesSpecialTreatments"] = "dataProcessingInCountriesSpecialTreatments";
    ServiceTemplateField["DeleteTechnicalDefinitionsAfterOptOut"] = "deleteTechnicalDefinitionsAfterOptOut";
    ServiceTemplateField["DynamicFields"] = "dynamicFields";
    ServiceTemplateField["DynamicFieldIds"] = "dynamicFieldIds";
    ServiceTemplateField["ExecuteCodeOptInWhenNoTagManagerConsentIsGiven"] = "executeCodeOptInWhenNoTagManagerConsentIsGiven";
    ServiceTemplateField["ExecuteCodeOptOutWhenNoTagManagerConsentIsGiven"] = "executeCodeOptOutWhenNoTagManagerConsentIsGiven";
    ServiceTemplateField["ExecutePriority"] = "executePriority";
    ServiceTemplateField["GoogleConsentModeConsentTypes"] = "googleConsentModeConsentTypes";
    ServiceTemplateField["Group"] = "group";
    ServiceTemplateField["GroupNotice"] = "groupNotice";
    ServiceTemplateField["GroupNoticeTranslationFlags"] = "groupNoticeTranslationFlags";
    ServiceTemplateField["IsCdn"] = "isCdn";
    ServiceTemplateField["IsEmbeddingOnlyExternalResources"] = "isEmbeddingOnlyExternalResources";
    ServiceTemplateField["IsProviderCurrentWebsite"] = "isProviderCurrentWebsite";
    ServiceTemplateField["LegalBasis"] = "legalBasis";
    ServiceTemplateField["LegalBasisNotice"] = "legalBasisNotice";
    ServiceTemplateField["LegalBasisNoticeTranslationFlags"] = "legalBasisNoticeTranslationFlags";
    ServiceTemplateField["Provider"] = "provider";
    ServiceTemplateField["ProviderContact"] = "providerContact";
    ServiceTemplateField["ProviderLegalNoticeUrl"] = "providerLegalNoticeUrl";
    ServiceTemplateField["ProviderLegalNoticeUrlTranslationFlags"] = "providerLegalNoticeUrlTranslationFlags";
    ServiceTemplateField["ProviderNotice"] = "providerNotice";
    ServiceTemplateField["ProviderNoticeTranslationFlags"] = "providerNoticeTranslationFlags";
    ServiceTemplateField["ProviderPrivacyPolicyUrl"] = "providerPrivacyPolicyUrl";
    ServiceTemplateField["ProviderPrivacyPolicyUrlTranslationFlags"] = "providerPrivacyPolicyUrlTranslationFlags";
    ServiceTemplateField["ProviderText"] = "providerText";
    ServiceTemplateField["ProviderTranslationFlags"] = "providerTranslationFlags";
    ServiceTemplateField["Purposes"] = "purposes";
    ServiceTemplateField["PurposeIds"] = "purposeIds";
    ServiceTemplateField["PurposeText"] = "purposeText";
    ServiceTemplateField["SccConclusionInstructionsNotice"] = "sccConclusionInstructionsNotice";
    ServiceTemplateField["SccConclusionInstructionsNoticeTranslationFlags"] = "sccConclusionInstructionsNoticeTranslationFlags";
    ServiceTemplateField["ShouldUncheckContentBlockerCheckbox"] = "shouldUncheckContentBlockerCheckbox";
    ServiceTemplateField["ShouldUncheckContentBlockerCheckboxWhenOneOf"] = "shouldUncheckContentBlockerCheckboxWhenOneOf";
    ServiceTemplateField["TagManagerOptInEventName"] = "tagManagerOptInEventName";
    ServiceTemplateField["TagManagerOptOutEventName"] = "tagManagerOptOutEventName";
    ServiceTemplateField["TechnicalDefinitions"] = "technicalDefinitions";
    ServiceTemplateField["TechnicalDefinitionIds"] = "technicalDefinitionIds";
    ServiceTemplateField["TechnicalHandlingNotice"] = "technicalHandlingNotice";
    ServiceTemplateField["TechnicalHandlingNoticeTranslationFlags"] = "technicalHandlingNoticeTranslationFlags";
})(ServiceTemplateField || (ServiceTemplateField = {}));
const EXPORT_REMOVABLE_SERVICE = [
    "id",
    "logo",
    "logoId",
    "release",
    "releaseId",
    "extends",
    "next",
    "nextId",
    "pre",
    "preId",
    "extendsId",
    "translationIds",
    "extendedTemplateId",
    "translationInfo",
    "purposeIds",
    "dynamicFieldIds",
    "technicalDefinitionIds",
    "translatableRequiredFields",
    "translatedRequiredFields",
    "translatableOptionalFields",
    "translatedOptionalFields",
    "translationFlaggedFields",
    "version"
];
Object.freeze(EXPORT_REMOVABLE_SERVICE);
export { EServiceTemplateDataProcessingInCountriesSpecialTreatment, EServiceTemplateGoogleConsentModeTypes, EServiceTemplateGroup, EServiceTemplateLegalBasis, EXPORT_REMOVABLE_SERVICE, EXTENDABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST, ServiceTemplateField, SERVICE_CDN_HIDDEN_CLIENT_PROPS, TRANSLATABLE_SERVICE_TEMPLATE_ATTRIBUTES_LIST };
