import { OPT_IN_EVENT } from "@devowl-wp/cookie-consent-web-client";
import { OPT_IN_CONTENT_BLOCKER, putHtmlCodeToDom } from "@devowl-wp/headless-content-unblocker";
import { waitObject } from "@devowl-wp/react-utils";
let amazonWidgetsSlotNum = 0;
/**
 * Some templates have custom opt-in coding, e. g. Amazon Widgets.
 */ function listenTemplateOptIn() {
    document.addEventListener(OPT_IN_EVENT, async (param)=>{
        let { detail: { service: { presetId, codeOptIn, codeDynamics } } } = param;
        switch(presetId){
            case "amazon-associates-widget":
                {
                    const { amznAssoWidgetHtmlId } = codeDynamics || {};
                    if (amznAssoWidgetHtmlId) {
                        const element = document.getElementById(amznAssoWidgetHtmlId);
                        if (element) {
                            const slotNum = amazonWidgetsSlotNum;
                            amazonWidgetsSlotNum++;
                            putHtmlCodeToDom(codeOptIn, codeDynamics, element);
                            // Amazon Widgets is using `document.write`, we need to manually move the ad container...
                            const adContainer = await waitObject(()=>document.querySelector(`[id^="amzn_assoc_ad_div_"][id$="${slotNum}"]`), 500, 50);
                            if (adContainer) {
                                element.appendChild(adContainer);
                            }
                        }
                    }
                    break;
                }
            case "google-maps":
                {
                    document.addEventListener(OPT_IN_CONTENT_BLOCKER, async (param)=>{
                        let { detail: { element } } = param;
                        const { et_pb_map_init, jQuery } = window;
                        if (jQuery && element.matches(".et_pb_map") && et_pb_map_init) {
                            await waitObject(()=>window.google);
                            et_pb_map_init(jQuery(element).parent());
                        }
                    });
                    break;
                }
            default:
                break;
        }
    });
}
export { listenTemplateOptIn };
