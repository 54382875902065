import { isHost } from "./isHost.js";
import { isUrl } from "./isUrl.js";
/**
 * Decode HTML entities in a given string.
 *
 * @see https://stackoverflow.com/a/34064434/5506547
 */ function htmlDecode(input) {
    const inputIsUrl = isUrl(input) && !isHost(input);
    if (inputIsUrl) {
        var _doc_querySelector;
        // URLs needs to be handled a bit different as e.g. `?regionid` gets parsed to `&#39;ionid=`
        const doc = new DOMParser().parseFromString(`<a href="${input}"></a>`, "text/html");
        return (_doc_querySelector = doc.querySelector("a")) == null ? void 0 : _doc_querySelector.href;
    } else {
        // Keep XSS protection intact
        const doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }
}
export { htmlDecode };
