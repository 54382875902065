// Also ported to `view/Blocker.php`
const HTML_ATTRIBUTE_CAPTURE_PREFIX = "consent-original";
const HTML_ATTRIBUTE_CAPTURE_CLICK_PREFIX = "consent-click-original";
const HTML_ATTRIBUTE_CAPTURE_SUFFIX = "_";
const HTML_ATTRIBUTE_BY = "consent-by";
// TODO: cookie -> required?
const HTML_ATTRIBUTE_COOKIE_IDS = "consent-required";
const HTML_ATTRIBUTE_VISUAL_PARENT = "consent-visual-use-parent";
const HTML_ATTRIBUTE_VISUAL_FORCE = "consent-visual-force";
const HTML_ATTRIBUTE_VISUAL_PAINT_MODE = "consent-visual-paint-mode";
const HTML_ATTRIBUTE_VISUAL_PARENT_HIDE = "consent-visual-use-parent-hide";
const HTML_ATTRIBUTE_INLINE = "consent-inline";
const HTML_ATTRIBUTE_INLINE_STYLE = "consent-inline-style";
const HTML_ATTRIBUTE_BLOCKER_ID = "consent-id";
const HTML_TAG_CONSENT_SCRIPT = "script";
const HTML_ATTRIBUTE_BLOCKER_CONNECTED = "consent-blocker-connected";
const HTML_ATTRIBUTE_BLOCKER_CONNECTED_PRESERVED = "consent-blocker-connected-pres";
const HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE = "consent-transaction-complete";
const HTML_ATTRIBUTE_TRANSFORM_WRAPPER = "consent-transform-wrapper";
const HTML_ATTRIBUTE_TRANSFORM_WRAPPER_VALUE = "1";
const HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN = "consent-strict-hidden";
const HTML_ATTRIBUTE_WAS_PREVIOUSLY_DISPLAY_STYLE = "consent-previous-display-style";
const HTML_ATTRIBUTE_RESET_PARENT = "consent-cb-reset-parent";
const HTML_ATTRIBUTE_RESET_PARENT_VALUE = "1";
const HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER = "consent-cb-reset-parent-is-ratio";
const HTML_ATTRIBUTE_GOT_CLICKED = "consent-got-clicked";
const HTML_ATTRIBUTE_GOT_CLICKED_VALUE = "1";
const HTML_ATTRIBUTE_GOT_CLICKED_VALUE_DO_NOT_USE_IN_NESTED = "2";
const HTML_ATTRIBUTE_THUMBNAIL = "consent-thumbnail";
const HTML_ATTRIBUTE_THUMBNAIL_SUGGESTION = "consent-thumbnail-suggestion";
const HTML_ATTRIBUTE_DELEGATE_CLICK = "consent-delegate-click";
const HTML_ATTRIBUTE_JQUERY_HIJACK_EACH = "consent-jquery-hijack-each";
const HTML_ATTRIBUTE_JQUERY_HIJACK_FN = "consent-jquery-hijack-fn";
const HTML_ATTRIBUTE_CLICK_DISPATCH_RESIZE = "consent-click-dispatch-resize";
const HTML_ATTRIBUTE_CONFIRM = "consent-confirm";
const HTML_ATTRIBUTE_HERO_DIALOG_DEFAULT_OPEN = "consent-hero-dialog-default-open";
export { HTML_ATTRIBUTE_CAPTURE_PREFIX, HTML_ATTRIBUTE_CAPTURE_CLICK_PREFIX, HTML_ATTRIBUTE_CAPTURE_SUFFIX, HTML_ATTRIBUTE_BY, HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_VISUAL_PARENT, HTML_ATTRIBUTE_VISUAL_FORCE, HTML_ATTRIBUTE_VISUAL_PAINT_MODE, HTML_ATTRIBUTE_VISUAL_PARENT_HIDE, HTML_ATTRIBUTE_INLINE, HTML_ATTRIBUTE_INLINE_STYLE, HTML_ATTRIBUTE_BLOCKER_ID, HTML_TAG_CONSENT_SCRIPT, HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_BLOCKER_CONNECTED_PRESERVED, HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, HTML_ATTRIBUTE_TRANSFORM_WRAPPER, HTML_ATTRIBUTE_TRANSFORM_WRAPPER_VALUE, HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN, HTML_ATTRIBUTE_WAS_PREVIOUSLY_DISPLAY_STYLE, HTML_ATTRIBUTE_RESET_PARENT, HTML_ATTRIBUTE_RESET_PARENT_VALUE, HTML_ATTRIBUTE_RESET_PARENT_IS_RATIO_CONTAINER, HTML_ATTRIBUTE_GOT_CLICKED, HTML_ATTRIBUTE_GOT_CLICKED_VALUE, HTML_ATTRIBUTE_GOT_CLICKED_VALUE_DO_NOT_USE_IN_NESTED, HTML_ATTRIBUTE_THUMBNAIL, HTML_ATTRIBUTE_THUMBNAIL_SUGGESTION, HTML_ATTRIBUTE_DELEGATE_CLICK, HTML_ATTRIBUTE_JQUERY_HIJACK_EACH, HTML_ATTRIBUTE_JQUERY_HIJACK_FN, HTML_ATTRIBUTE_CLICK_DISPATCH_RESIZE, HTML_ATTRIBUTE_CONFIRM, HTML_ATTRIBUTE_HERO_DIALOG_DEFAULT_OPEN };
