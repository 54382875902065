import { commonRequest } from "@devowl-wp/utils";
import { getOptionsFromWindow } from "../utils/getOptionsFromWindow.js";
import { locationRestConsentClearDelete } from "../wp-api/consentClear.delete.js";
/**
 * Delete cookies on server side (currently only `http`).
 */ function deleteCookiesServer(cookies) {
    const { restNamespace, restRoot, restQuery, restNonce, restPathObfuscateOffset } = getOptionsFromWindow();
    commonRequest({
        location: locationRestConsentClearDelete,
        options: {
            restNamespace,
            restRoot,
            restQuery,
            restNonce,
            restPathObfuscateOffset
        },
        sendRestNonce: false,
        params: {
            cookies: cookies.join(",")
        }
    });
}
export { deleteCookiesServer };
