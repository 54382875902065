import { waitObject, yieldMainThread } from "@devowl-wp/react-utils";
import { getDefaultDecision } from "../decision/getDefaultDecision.js";
import { getUserDecision } from "../decision/getUserDecision.js";
/**
 * Main class to manage cookie consents within your application.
 */ class CookieConsentManager {
    static #_ = this.BROADCAST_SIGNAL_APPLY_COOKIES = "applyCookies";
    constructor(options){
        const { decisionCookieName } = options;
        this.options = options;
        this.options.tcfCookieName = `${decisionCookieName}-tcf`;
        this.options.gcmCookieName = `${decisionCookieName}-gcm`;
        // Listen to consent changes in other tabs so we can apply cookies for this tab, too (but only once for the initial cookie banner representation)
        let otherTabListenerExecuted = false;
        window.addEventListener("storage", (param)=>{
            let { key, oldValue, newValue, isTrusted } = param;
            if (!otherTabListenerExecuted && key === this.getConsentQueueName() && newValue && isTrusted) {
                const parsedOldValue = JSON.parse(oldValue || "[]");
                const parsedNewValue = JSON.parse(newValue);
                if (parsedNewValue.length > parsedOldValue.length) {
                    // A new consent got added, so we are safe to say that another tab accepted the cookie banner
                    // At this time we have not yet the decision available in localStorage or HTTP cookie, so we
                    // need to wait for the consent to be available
                    otherTabListenerExecuted = true;
                    const previousConsentDecision = JSON.stringify(getUserDecision(decisionCookieName));
                    waitObject(()=>JSON.stringify(getUserDecision(decisionCookieName)) !== previousConsentDecision, 500, 20).then(()=>this.applyCookies({
                            type: "consent",
                            triggeredByOtherTab: true
                        }));
                }
            }
        });
        const fn = async ()=>{
            const { retryPersistFromQueue } = await import(/* webpackChunkName: "banner-lazy", webpackMode: "lazy-once" */ "../decision/retryPersistFromQueue.js");
            // Only retry when the queue is filled with items
            const start = (tryImmediate)=>{
                const dispose = retryPersistFromQueue(this, tryImmediate);
                window.addEventListener("beforeunload", dispose);
            };
            if (this.getConsentQueue().length > 0) {
                start(true);
            } else {
                const listener = (param)=>{
                    let { key, newValue } = param;
                    const fromCurrentTab = key === this.getConsentQueueName() && newValue;
                    const fromOtherTab = key === this.getConsentQueueName(true) && !newValue;
                    if (fromCurrentTab || fromOtherTab) {
                        start(fromOtherTab);
                        window.removeEventListener("storage", listener);
                    }
                };
                window.addEventListener("storage", listener);
            }
        };
        if (window.requestIdleCallback) {
            requestIdleCallback(fn);
        } else {
            yieldMainThread().then(fn);
        }
    }
    async applyCookies(options) {
        const { apply: doApply } = await import(/* webpackChunkName: "banner-lazy", webpackMode: "lazy-once" */ "../apply/apply.js");
        await doApply({
            ...options,
            ...this.options
        });
    }
    /**
     * If you have passed a `persistConsent` as option to the manager constructor, you can use this method.
     * This method wraps your passed callback and if an error occurs, it accordingly handles the error and pushes
     * the transaction into a queue. The queue has a lifecycle to get retried at a later stage when e.g. your server
     * is available again.
     */ async persistConsent(transaction) {
        const { persistWithQueueFallback } = await import(/* webpackChunkName: "banner-lazy", webpackMode: "lazy-once" */ "../decision/persistWithQueueFallback.js");
        return await persistWithQueueFallback(transaction, this);
    }
    getUserDecision(onlyUptoDate) {
        const decision = getUserDecision(this.getOption("decisionCookieName"));
        return onlyUptoDate === true ? decision ? decision.revision === this.getOption("revisionHash") ? decision : false : false : decision;
    }
    getDefaultDecision(respectLegitimateInterests) {
        if (respectLegitimateInterests === void 0) respectLegitimateInterests = true;
        return getDefaultDecision(this.options.groups, respectLegitimateInterests);
    }
    getOption(name) {
        return this.options[name];
    }
    getOptions() {
        return this.options;
    }
    getConsentQueueName(lock) {
        if (lock === void 0) lock = false;
        return `${this.options.consentQueueLocalStorageName}${lock ? "-lock" : ""}`;
    }
    getConsentQueue() {
        return JSON.parse(localStorage.getItem(this.getConsentQueueName()) || "[]");
    }
    setConsentQueue(queue) {
        const key = this.getConsentQueueName();
        const oldValue = localStorage.getItem("test");
        const newValue = queue.length > 0 ? JSON.stringify(queue) : null;
        if (newValue) {
            localStorage.setItem(key, newValue);
        } else {
            localStorage.removeItem(key);
        }
        // Send custom event so the current browser can listen to the queue change (see constructor)
        // https://stackoverflow.com/a/72428465/5506547
        window.dispatchEvent(new StorageEvent("storage", {
            key,
            oldValue,
            newValue
        }));
    }
    isConsentQueueLocked(set) {
        const current = new Date().getTime();
        const lockName = this.getConsentQueueName(true);
        if (set === false) {
            localStorage.removeItem(lockName);
        } else if (set === true) {
            localStorage.setItem(lockName, `${current + 1000 * 60}`);
        }
        const time = +(localStorage.getItem(lockName) || 0);
        return !(current > time);
    }
}
export { CookieConsentManager };
