import Cookie from "js-cookie";
import { EServiceTemplateGoogleConsentModeTypes } from "@devowl-wp/api-real-cookie-banner";
/**
 * Get current user decision for Google Consent Mode from the cookie value.
 */ function getUserGcmDecision(cookieName, /**
     * Depending on an implicit consent we need to fill the decision (e.g. when implicitely all services are
     * accepted, we also need to accept all GCM consent types)
     */ buttonClicked) {
    // Got it saved locally?
    const localConsent = localStorage.getItem(cookieName);
    if (localConsent) {
        return JSON.parse(localConsent);
    }
    const cookieValue = Cookie.get(cookieName);
    return (buttonClicked == null ? void 0 : buttonClicked.startsWith("implicit")) ? buttonClicked === "implicit_all" ? Object.values(EServiceTemplateGoogleConsentModeTypes) : [] : JSON.parse(cookieValue || "[]");
}
export { getUserGcmDecision };
